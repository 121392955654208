import React, { Component } from "react";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import { Dropdown, DropdownButton,Tabs, Tab } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import SynopsLoader from "../Shared/SynopsLoader";
import i18n from "i18next";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { store } from "../reducers/configureStore";
import { LocalApiBaseUrl } from "../Shared/Constant";
import Moment from "react-moment";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import { connect } from "react-redux";
import HMJourneyMetricView from "../HumanMachineJourney/HMJourneyMetricView";
import { Modal } from "react-bootstrap";
import TilesView from "../LandingPage/TilesView.jsx"
import MultiSelectDropDownComp from "../LandingPage/MultiSelectDropDownComp"
import SingleDropDownHnM from "./SingleDropDownHnM";

class HumanMachineJourney extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateShow: new Date(),
      MachinePeriod: null,
      HumanPeriod: null,
      soCatForHumanJourney: [],
      toolTypes: [],
      humanMetricsApiResponse: [],
      automationTypes: [],
      automationDropdownResp: [],
      machineMetricsResp: [],
      hybridNumerator: 0,
      hybridDenominator: 0,
      ratioNum: 0,
      ratioDen: 0,
      selectedToolTypeID: 0,
      selectedMacDataMetDatID: 0,
      selectedSOCatID: 0,
      selectedSOCatName: '',
      LastRunTime: new Date(),
      isHuman: true,
      showAutomationType: true,
      showType: true,
      allLocation: {
        Location: "All",
        checked: true,
        filterApply: true,
      },
      allGroup:{
        filterApply: true,
      },
      elementDetails: [],
      locations: [],
      groups: [],
      groupsOriginal: [],
      LocationElements: [],
      isAPILoading: false,
      locationAPICall: false,
      groupAPICall: false,
    };
    this.onHumanSOCategoryChange = this.onHumanSOCategoryChange.bind(this);

    this.onToolTypeChange = this.onToolTypeChange.bind(this);
    this.onAutomationTypeChange = this.onAutomationTypeChange.bind(this);
    this.gcd_two_numbers = this.gcd_two_numbers.bind(this);
  }
  dateChange = (val) => {
    this.setState({
      dateShow: val,
    });
  };
  removeDuplicates = (myArr, prop) => {
    return myArr.filter((obj, pos, arr) => {
      return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  };
  onToolTypeChange(event) {
    let selectedToolTypeID = event;
    let filterClient = this.props.filterDetails.Client;
    let filterProcess = this.props.filterDetails.process;
    let filterLocations = this.props.filterDetails.Locations;
    this.setState({ machineMetricsResp: [], automationTypes: [] });

    this.setState({
      selectedToolTypeID: parseInt(selectedToolTypeID),
      toolTypeDropDownSelection: this.state.toolTypes.filter(f => { return (f.key == selectedToolTypeID) }),
      automationTypeDropDownSelection: "Automation Type"
    });


    if (parseInt(selectedToolTypeID) !== 0) {
      axiosInstance
        .get(`${LocalApiBaseUrl}SOFilter/MachineJourneyMetricDataMachineJourney`, {
          params: {
            ToolTypeID: parseInt(selectedToolTypeID),
            OrgEntityID: parseInt(filterClient),
            OfferingID: parseInt(filterProcess),
            LocationID: parseInt(filterLocations),
          },
        })
        .then((response) => {
          if (response.data.length > 0) {
            this.setState({ automationDropdownResp: response.data, showAutomationType: true });
            let mappedArray = response.data.map((elt) => ({
              MachineJourneyMetricDataID: elt.MachineJourneyMetricDataID,
              ToolTypeID: elt.ToolTypeID,
              Automation: elt.Automation,
            }));
            let arrayWithoutDuplicateAutomations = this.removeDuplicates(mappedArray, "Automation");

            let automationDdlValues =
              arrayWithoutDuplicateAutomations.length > 0 &&
              arrayWithoutDuplicateAutomations.map((x) => {
                return (
                  <option key={x.MachineJourneyMetricDataID} value={x.MachineJourneyMetricDataID}>
                    {x.Automation}
                  </option>
                );
              }, this);

            this.setState({ automationTypes: automationDdlValues });
          }
          else {
            this.setState({ showAutomationType: false });
          }
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });

    } else {
      //clear the automation dropdown here
      this.setState({ selectedMacDataMetDatID: 0 });
    }
  }

  onAutomationTypeChange(event) {
    let selectedMacDataMetDatID = parseInt(event);

    this.setState({ selectedMacDataMetDatID: selectedMacDataMetDatID, automationTypeDropDownSelection: this.state.automationTypes.filter(f => { return (f.key == selectedMacDataMetDatID) }) });

    const result = this.state.automationDropdownResp.filter(function (itm) {
      return itm.MachineJourneyMetricDataID == selectedMacDataMetDatID;
    });

    if (result.length > 0) {
      let theTooltypeid = result[0].ToolTypeID;
      let theAutomation = result[0].Automation;

      let filterClient = this.props.filterDetails.Client;
      let filterProcess = this.props.filterDetails.process;
      let filterLocations = this.props.filterDetails.Locations;
      let filterDate = this.props.filterDetails.Month; //HMJ tile not dependant on the month selection in filter dropdowns. Always pulls current month data

      let todayDate = new Date();
      var firstDayOfCurrentMonth = new Date(todayDate.getFullYear(), todayDate.getMonth(), 1);

      let finalDate = firstDayOfCurrentMonth.toDateString();

      axiosInstance
        .get(`${LocalApiBaseUrl}SOFilter/GetMachineJourneyData`, {
          params: {
            ToolTypeID: theTooltypeid,
            Automation: theAutomation,
            OrgEntityID: filterClient,
            OfferingID: filterProcess,
            LocationID: filterLocations,
            MonthDT: finalDate,
            LanguageID: this.props.languageData.id,
          },
        })
        .then((response) => {
          this.setState({ machineMetricsResp: response.data });
          if (response.data.length > 0) {
            let startDate = moment
              .utc(response.data[0].PeriodDT)
              .local()
              .format("MM/DD/YY");
            let endDate = moment
              .utc(response.data[0].UpdatedTS)
              .local()
              .format("MM/DD/YY");
            let MachinePeriod = `${startDate} — ${endDate}`;
            this.setState({
              MachinePeriod: MachinePeriod,
              LastRunTime: response.data[0].LastUpdatedTS
            });
          }
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });

    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.humanMetricsSum > 0) {
      this.setState({ hybridNumerator: nextProps.humanMetricsSum });
    }

    //clearing the retained state of the dropdowns here
    this.setState({
      automationTypes: [],
      selectedMacDataMetDatID: 0,
      selectedToolTypeID: 0,
      selectedSOCatID: 0,
      selectedSOCatName: '',
    });
  }

  componentDidMount() {
    //dropdown values for human tile

    //choose dropdowns then unselect filter dropdowns - these dropdown selections are retained - yet to be tested
    this.setState({
      automationTypes: [],
      selectedMacDataMetDatID: 0,
      selectedToolTypeID: 0,
      selectedSOCatID: 0,
      selectedSOCatName: '',
    });
    let filterClient = this.props.filterDetails.Client;
    let filterProcess = this.props.filterDetails.process;
    let filterLocations = this.props.filterDetails.Locations;
    let filterDate = this.props.filterDetails.Month; // HMJ tile not dependant on this filter dropdown month selection

    let todayDate = new Date();
    var firstDayOfCurrentMonth = new Date(todayDate.getFullYear(), todayDate.getMonth(), 1);

    let finalDate = firstDayOfCurrentMonth.toDateString();

    //make hybrid api call here

    //API call to fetch denominator value of hybrid tile
    if (this.props.humanMetricsSum && this.props.humanMetricsSum !== 0) {
      //calculate the sum of human tile metricvalues here - ratio numerator

      this.setState({
        hybridNumerator: this.props.humanMetricsSum,
      });
    }
    //calculate the sum of human tile metricvalues here - ratio numerator end

    axiosInstance
      .get(`${LocalApiBaseUrl}SOFilter/GetHybridWorkData`, {
        params: {
          OrgEntityID: parseInt(filterClient),
          OfferingID: parseInt(filterProcess),
          LocationID: parseInt(filterLocations),
          PeriodDT: finalDate,
        },
      })
      .then((response) => {
        if (response.data.length > 0) {
          this.setState({
            hybridDenominator: parseInt(response.data[0].MetricValue),
          });
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });


    //make hybrid api call here end

    axiosInstance
      .get(`${LocalApiBaseUrl}SOFilter/GetSOCategoryforHumanJourney`, {
        params: {
          SOCategory: "Human Journey",
          OrgEntityID: parseInt(this.props.filterDetails.Client),
          OfferingID: parseInt(this.props.filterDetails.process),
          LocationID: parseInt(this.props.filterDetails.Locations),
          LanguageID: this.props.languageData.id
        },
      })
      .then((response) => {
        if (response.data != null) {
          let soCatForHumanJourneyVar = response.data;
          let soCatForHumanJourneyValues =
            soCatForHumanJourneyVar.length > 0 &&
            soCatForHumanJourneyVar.map((x) => {
              return (
                <option key={x.SOCategoryID} value={x.SOCategoryID}>
                  {x.SOCategoryName}
                </option>
              );
            }, this);
          this.setState({ soCatForHumanJourney: soCatForHumanJourneyValues, liveFeedHumanMachine: response.data });
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });


    //dropdown values for main filter of machine tile
    axiosInstance
      .get(`${LocalApiBaseUrl}SOFilter/GetToolTypeforMachineJourney`, {
        params: {
          LanguageID: this.props.languageData.id,
          OrgEntityID: parseInt(this.props.filterDetails.Client),
          OfferingID: parseInt(this.props.filterDetails.process),
          LocationID: parseInt(this.props.filterDetails.Locations),
        },
      })
      .then((response) => {
        let toolsList = response.data;
        let toolListValues =
          toolsList.length > 0 &&
          toolsList.map((x) => {
            return (
              <option key={x.ID} value={x.ID}>
                {x.UIFilterValue}
              </option>
            );
          }, this);
        this.setState({ toolTypes: toolListValues });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });

  }

  gcd_two_numbers(x, y) {
    if (typeof x !== "number" || typeof y !== "number") return false;
    x = Math.abs(x);
    y = Math.abs(y);
    while (y) {
      var t = y;
      y = x % y;
      x = t;
    }
    return x;
  }

  onHumanSOCategoryChange(event) {
    let selectedSOCatID = event;
    const { liveFeedHumanMachine } = this.state;
    let selectedSOCatName = liveFeedHumanMachine.filter((a)=>a.SOCategoryID === parseInt(selectedSOCatID))
    selectedSOCatName = selectedSOCatName.length >0 ? selectedSOCatName[0].SOCategoryName:'';
    this.setState({ selectedSOCatID: parseInt(selectedSOCatID), selectedSOCatName, humanDropDownSelection: this.state.soCatForHumanJourney.filter(f => { return (f.key == selectedSOCatID) }) });

    let todayDate = new Date();
    var firstDayOfCurrentMonth = new Date(todayDate.getFullYear(), todayDate.getMonth(), 1);

    let finalDate = firstDayOfCurrentMonth.toDateString();

    let filterClient = this.props.filterDetails.Client;
    let filterProcess = this.props.filterDetails.process;
    let filterLocations = this.props.filterDetails.Locations;
    let filterDate = this.props.filterDetails.Month; // HMJ tile not dependant on this filter dropdown month selection
    var data = {
      SOCategoryID: parseInt(selectedSOCatID),
      OrgEntityID: parseInt(filterClient),
      OfferingID: parseInt(filterProcess),
      LocationID: parseInt(filterLocations),
      MonthDT: finalDate,
    };

    if (data.SOCategoryID !== 0 && selectedSOCatName !== 'Productivity') {
      this.setState({isAPILoading: true})
      axiosInstance
        .post(`${LocalApiBaseUrl}SOFilter/GetHumanMachineJourneyData`, data, {
          params: { LanguageID: this.props.languageData.id }
        })
        .then((response) => {
          this.setState({ humanMetricsApiResponse: response.data, showType: true, isAPILoading: false });

          if (this.state.humanMetricsApiResponse.length > 0) {
            let startDate = moment
              .utc(response.data[0].PeriodDT)
              .local()
              .format("MM/DD/YY");
            let endDate = moment
              .utc(response.data[0].UpdatedTS)
              .local()
              .format("MM/DD/YY");
            let HumanPeriod = `${startDate} — ${endDate}`;
            this.setState({
              LastRunTime: this.state.humanMetricsApiResponse[0].LastUpdatedTS,
              HumanPeriod: HumanPeriod,
            });
          }
          else {
            this.setState({ showType: false });
          }
        })
        .catch((error) => {
          this.setState({isAPILoading: false})
          trycatchAlertPopup(error);
        });
    }
    else
    {
      let dataParams = {
        ConnectorName:"IWGPHDataLake",
        ClientId: parseInt(filterClient),
        OfferingId: parseInt(filterProcess),
        LocationId: parseInt(filterLocations),
        LanguageID: 1
      }
      this.setState({isAPILoading: true})
      axiosInstance
        .get(`${LocalApiBaseUrl}PulseDashBoard/GetPulseSummaryDataForIWGPH`, {
          params: {...dataParams}
        }).then((response) => {
          this.setState({isAPILoading: false, showType: true });
          const { allLocation, allGroup } = this.state;
          let responseData = response.data;
          let exclude = ["Attendance","ShrinkageHrs"]
          responseData.elementDetails = responseData.elementDetails.filter(a=> !(exclude.filter(b=>b === a.MetricName).length >0))
          let elements = responseData.elementDetails.sort((a, b) =>
          a.MetricName > b.MetricName ? 1 : -1
        );
        let groupsOriginal = responseData.locationList;
        groupsOriginal =
        groupsOriginal.length > 0
          ? groupsOriginal.map((each) => {
              each.GroupList = each.GroupList.map((a) => ({
                ...a,
                LocationName: each.locationName_En,
                type: "Group",
                checked: true,
                label: a.GroupName_En,
                value:a.GroupName_En
              }));
              return each;
            })
          : [];
        let locations = responseData.locationList.map(loc=>({Location: loc.locationName,
          LocationID: loc.locationId,
          checked: true,
          type: "location",
          label: loc.locationName,
          value: loc.locationName,}))
        let groups = groupsOriginal.map((each) => each.GroupList).flat(1);  
        groups.sort((a,b)=>a.label.toLowerCase() > b.label.toLowerCase() ? 1: -1)
        locations.sort((a,b)=>a.label.toLowerCase() > b.label.toLowerCase() ? 1: -1)
        
    let startDate = moment
    .utc(responseData.PeriodDT)
    .local()
    .format("MM/DD/YY");
  let endDate = moment
    .utc(new Date())
    .local()
    .format("MM/DD/YY");
  let HumanPeriod = `${startDate} — ${endDate}`;
  allLocation.filterApply = true;
  allGroup.filterApply = true;
    this.setState({
            humanMetricsApiResponse: responseData.elementDetails,
            elementDetails: elements,
            locations,
            HumanPeriod,
            groups,
            groupsOriginal,
            allLocation,
            allGroup
          });
        }).catch(error=>{
          this.setState({isAPILoading: false, showType: false})
        })
    }
  }
  setIsHuman = (e) => {
    this.setState({ isHuman: (e == "Human") })
  }

  callBackOutsideClick = () =>{
    let { locations, groups,allLocation,locationAPICall, groupAPICall } = this.state;
    if(!allLocation.filterApply && !locations.every(a=>a.checked === false) && (locationAPICall || groupAPICall))
    {
      locations = locations.map(loc=>{
        if(groups.filter(grp=>grp.LocationName === loc.Location && grp.checked).length >0)
        {
          loc.checked = true;
        }
        else
        {
          loc.checked = false
        }
        return loc
      });


      this.setState({
        locations,
        locationAPICall:false,
        groupAPICall:false,
      },()=>{
        this.handleDropdownApply()
      })
    }
  }

  handleDropdownApply = () =>{
    
    let { locations, groups,allLocation } = this.state;
    const { filterDetails } = this.props

    let filterClient = filterDetails.Client;
    let filterProcess = filterDetails.process;
    let filterLocations = filterDetails.Locations;
    
    
      let resultList = []
      locations = locations.filter(a=>a.checked)
      locations.map((loc)=>{
        let obj ={}
        obj.locationName = loc.Location;
        obj.locationName_En = loc.Location;
        obj.locationId = 0;
        obj.GroupList=groups.filter((grp) => grp.LocationName === loc.Location && grp.checked).map((grp)=>({GroupName:grp.GroupName,GroupName_En:grp.GroupName_En}))
        resultList.push(obj);
      })
      
      allLocation.filterApply = false;
      this.setState({
        isAPILoading:true,
        allLocation
      })
      let data = {
        ToolName: "IWGPHDataLake",
        OrgEntityID: parseInt(filterClient),
        OfferingID: parseInt(filterProcess),
        LocationIDSFilter: [],
        LanguageID: parseInt(store.getState().CurrentFilter.languageData.id),
        SelectedLocations:resultList,
        LocationID: parseInt(filterLocations),
      };
      return new Promise((resolve, reject) => {
        axiosInstance
          .post(`${LocalApiBaseUrl}PulseDashBoard/GetFilterSummaryViewForIWGPH`, data).then((response)=>{
            this.setState({
              isAPILoading:false
            })
            let temp = response.data;
            let exclude = ["Attendance","ShrinkageHrs"]
            temp = temp.filter(a=> !(exclude.filter(b=>b === a.MetricName).length >0))
            let LocationElements = [];
            resultList.map((loc) => {
              let obj = {
                LocationName: loc.locationName,
                LocationID: loc.LocationID,
                elementDetails: [],
              };
  
              temp.map((element) => {
                if (String(element.LocationName) === String(loc.locationName)) {
                  let obj2 = {
                    MetricName: element.MetricName,
                    Value: element.Value,
                    Unit: element.Unit,
                    DataSource: element.DataSource
                  };
                  obj.elementDetails.push(obj2);
                }
              });
              obj.elementDetails = obj.elementDetails.sort((a, b) =>
                a.MetricName.toLowerCase() > b.MetricName.toLowerCase() ? 1 : -1
              );
             // obj.Groups = [{ groupName: "GP1" }, { groupName: "GP2" }, { groupName: "GP3" }];
             obj.Groups = loc.GroupList;
              LocationElements.push(obj);
            });
  
            let locationElements = LocationElements;
            this.setState({
              LocationElements,
            })
          })
    })
    
   
}
selectall=(e,value)=>{

  let ischecked=e.target.checked;
  let { locations, groups, groupsOriginal,allLocation,allGroup,LocationElements } = this.state;
  allGroup.filterApply = ischecked
  if(value===0)
  {
    locations=locations.map(a=>({...a,checked:ischecked}))
    allLocation.filterApply = ischecked
    
    if(!ischecked)
    {
      groups = []
      LocationElements = []
    }
    
    else
    {
     groups = [...groupsOriginal].map((each) => each.GroupList).flat(1);
     groups=groups.map(a=>({...a,checked:ischecked}))
    }
  }
  
  else
  {
    groups=groups.map(a=>({...a,checked:ischecked}))
  }
  let locationAPICall= true;
  let groupAPICall = true;
  groups.sort((a,b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1: -1);
  locations.sort((a,b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1: -1);
 
this.setState({ locations, groups, allLocation, allGroup, LocationElements, locationAPICall, groupAPICall });
}
handleSelectedlocationOptions = (data, each, index) => {
  
  let { locations, groups,groupsOriginal,allGroup, allLocation } = this.state;

  allLocation.filterApply = false;
  let changedData = data.map((a) => {
    if (a.value == each.value) a.checked = !a.checked;
    return a;
  });
  if (each.type === "location") {
    locations = changedData;
    let found = changedData.filter(a=>a.value === each.value)
    let foundData = found.length >0? found[0].Location : ""
    let foundLocationChecked = found.length >0 ? found[0].checked:false; 
    if(!foundLocationChecked)
    {
      groups = [...groups].filter(a=>a.LocationName !== foundData)
    }
    else
    {
      let newGroups = groupsOriginal.filter(a=>a.locationName === foundData)[0].GroupList
      groups.push(...newGroups)
      groups.sort((a,b)=>a.label.toLowerCase() > b.label.toLowerCase() ? 1: -1)
    }
  } else {
    groups = changedData;
  }
  allGroup.filterApply = groups.every(a=>a.checked)
  groups.sort((a,b)=>a.label.toLowerCase() > b.label.toLowerCase() ? 1: -1)
  locations.sort((a,b)=>a.label.toLowerCase() > b.label.toLowerCase() ? 1: -1)
  let locationAPICall= true;
  let groupAPICall = true;
  this.setState({ locations, groups,allGroup, groupAPICall, locationAPICall, allLocation });
};
callBackClickInside = (index) =>{
  if(index === 0)
  {
    this.setState({
      locationAPICall:false,
    });
  }
  else
  {
    this.setState({
      groupAPICall:false,
    });
  }
}

  render() {
    const options = {
      loop: true,
      margin: 24,
      responsiveClass: "",
      dots: false,
      responsive: {
        0: {
          items: 1,
          nav: true,
        },
        600: {
          items: 2,
          nav: false,
        },
        1000: {
          items: 4,
          nav: true,
          loop: false,
        },
      },
    };
    const { elementDetails, allLocation,locations, groups,LocationElements, isAPILoading, allGroup, selectedSOCatName } = this.state
    

    return (
      <>

        <Modal.Header closeButton className="livefeed-header">
        <h4>{i18n.t("Live Feed")}

<span>({i18n.t("Last Updated")}:
  <Moment local format="MMMM DD YYYY, h:mm A">
    {this.state.LastRunTime}
  </Moment>
  )</span>
  </h4>
        </Modal.Header>
        <Modal.Body className="livefeed-body">
        <LoadingOverlay
            className="custom-loader"
            fadeSpeed={0}
            spinner={<SynopsLoader />}
            active={isAPILoading}
          />
          <div className="hmtab-layout">
            <div className="livefeed-period">
              <span>
                {this.state.isHuman ?
                  this.state.selectedSOCatID !== 0 && this.state.humanMetricsApiResponse.length > 0 && (
                    <>
                      {i18n.t("Period")}{": "}
                      {this.state.HumanPeriod}
                    </>
                  ) :
                  this.state.machineMetricsResp.length > 0 &&
                  this.state.selectedToolTypeID !== 0 &&
                  this.state.selectedMacDataMetDatID !== 0 && (
                    <>
                      {i18n.t("Period")}{": "}
                      {this.state.MachinePeriod}
                    </>
                  )
                }
              </span>
            </div>
            <Tabs defaultActiveKey="Human" onSelect={(e) => { this.setIsHuman(e) }}>

                 <Tab eventKey="Human" title="Human" >
                  <SingleDropDownHnM 
                  labelledby =' Metric Group'
                  options = {this.state.soCatForHumanJourney}
                  onSelectChange = {this.onHumanSOCategoryChange}
                  selected = {this.state.humanDropDownSelection ? this.state.humanDropDownSelection : "Metric Group"}
                  showAutomationType={true}
                  />
                 
                {this.state.selectedSOCatID !== 0 && selectedSOCatName === 'Productivity' && elementDetails.length > 0 && <div className="iwgph-filters">
                <MultiSelectDropDownComp
                                labelledBy="Location"
                                options={locations}
                                onChangeFun={this.handleSelectedlocationOptions}
                                value={locations.filter(a=>a.checked)}
                                selectall={this.selectall}
                                rowIndex={0}
                                selectAllStatus={allLocation.filterApply}
                                callBackOutsideClick={this.callBackOutsideClick}
                                checkboxDisabled={false}
                                callBackClickInside={this.callBackClickInside}
                              />
                <MultiSelectDropDownComp
                                labelledBy="Groups"
                                options={groups}
                                onChangeFun={this.handleSelectedlocationOptions}
                                value={groups.filter(a=>a.checked)}
                                selectall={this.selectall}
                                rowIndex={1}
                                selectAllStatus={allGroup.filterApply}
                                callBackOutsideClick={this.callBackOutsideClick}
                                checkboxDisabled={false}
                                callBackClickInside={this.callBackClickInside}
                              />
                </div>}
                
               
                {this.state.showType ?
                  <div className="livefeed-records iwgph-records">

                    {this.state.selectedSOCatID !== 0 ? (
                      selectedSOCatName === 'Productivity' ?
                      <TilesView
                      allLocation={allLocation}
                      elementDetails={elementDetails}
                      LocationElements={LocationElements}
                      showGroupsTooltip = {true}
                      />:
                      <>
                        {this.state.humanMetricsApiResponse.map((eachDetails) => (
                          <HMJourneyMetricView data={eachDetails}></HMJourneyMetricView>
                        ))}
                      </>
                    ) : null}
                    <ReactTooltip place="top" className="tipcolor" />

                  </div> : <h6>No Data Available</h6>}
              </Tab>


              <Tab eventKey="Machine" title="Machine">
                <div className="machine-dropdowns">
                <SingleDropDownHnM 
                id="dropdown-machine1"
                  labelledby ='Tool Type'
                  options = {this.state.toolTypes}
                  onSelectChange = {this.onToolTypeChange}
                  selected = {this.state.toolTypeDropDownSelection ? this.state.toolTypeDropDownSelection : "Tool Type"}
                showAutomationType={true}
                  />
                    <SingleDropDownHnM 
                id="dropdown-machine2"
                  labelledby ='Automation Type'
                  options = {this.state.automationTypes}
                  onSelectChange = {this.onAutomationTypeChange}
                  selected = {this.state.automationTypeDropDownSelection ? this.state.automationTypeDropDownSelection : "Automation Type"}
                  showAutomationType={this.state.showAutomationType}
                  />
                </div>
                <div className="livefeed-records">
                  {this.state.machineMetricsResp.length > 0 &&
                    this.state.selectedToolTypeID !== 0 &&
                    this.state.selectedMacDataMetDatID !== 0 ? (
                    <>
                      {this.state.machineMetricsResp.map((eachDetails) => {
                        return <HMJourneyMetricView data={eachDetails}></HMJourneyMetricView>
                      })}
                    </>
                  ) : null}

                  <ReactTooltip place="top" className="tipcolor" />
                </div>
              </Tab>



            </Tabs>
          </div>

        </Modal.Body>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    languageData: state.CurrentFilter.languageData,
  };
};
export default connect(mapStateToProps)(HumanMachineJourney);
