import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18n from 'i18next';
import OwlCarousel from 'react-owl-carousel';
import { Modal } from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay';
import _ from 'lodash';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import SynopsLoader from '../Shared/SynopsLoader';
import ToolSummaryLogo from '../Images/tool-summary-gradient.svg';
import defaultIcon from "../Images/assetsImages/defaultPulseToolImage.png"
import synopsAAAIcon from '../Images/SynOps_Gradient-Icon.jpg';
// IMPORT CUSTOM COMPONENTS/FUNCTIONS
import { LocalApiBaseUrlImage, LocalApiBaseUrl, trycatchAlertPopup, procurementOfferingList,idTokenstring } from '../Shared/Constant';
import axiosInstance from '../Shared/interceptor';
import AddAsserts from './addAsserts';
import TabQCScore from '../QCScore/TabQCScore';
import AddNewAsset from './addNewAsset';
import AssetsSummaryView from './assetsSummaryView';
import { store } from '../reducers/configureStore';
import AssetsLinkURLModal from './assetsLinkURLModal';

// IMPORT STYLES/IMAGES
import 'react-owl-carousel2/src/owl.carousel.css';
import 'react-owl-carousel2/src/owl.theme.default.css';
import './pulseStyle.css';
import synopsIcon from '../Images/Synops-Gradient-Transparent.svg';
import accentureLogo from '../Images/Accenture.png';
import noDataIcon from '../Images/no-data-icon.svg';
import noFilter from '../Images/funnel-icon.svg';
import noClientLogo from '../Images/client-0.svg';
import MORCaseStudy from './MORCaseStudy';
import { createBreadCrumbs } from '../Shared/commonFunctions';
import { NavLink } from "react-router-dom";

axiosInstance.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem(idTokenstring)}`;


const carouselOptions = {
  margin: 12,
  dots: false,
  autoWidth: true,
  autoplay: false,
  loop: false,
  nav: true,
  responsiveClass: true,
  slideBy: 3,
  responsive: {
    0: {
      items: 2,
    },
    400: {
      items: 2,
    },
    600: {
      items: 3,
    },
    700: {
      items: 3,
    },
    1000: {
      items: 3,
    },
  },
};

class LandingPageAdmin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      showQcGraph: false,
      selectedTab: '',
      isAPILoading: false,
      selectedsummaryViewAsset: '',
      selectedsummaryViewAssetURL: '',
      summaryStatus: false,
      acunterTools: [],
      TileID: 0,
      clientTools: [],
      landData: [],
      assetData: [],
      showAddAserts: false,
      showAddNewAsset: false,

      addassertDetails: {
        Offering: '',
        SubOffering: '',
        tabName: '',
        tabName_En: '',
        offeringId: this.props.pulseReduxData.offeringId,
      },
      editToolData: {},
      QCScorePercentStatus: false,
      selectedPulseDataCategory: '',
      enableAssetsLinkStatus: false,
      linkURL: '',
      isIFrame: false,
      newHelpFeaturesCount: 0,
      isHelpCountLoaded: false,
      OpriaURL: '',
      Period: ''
    };
  }

  GetHelpFeatureNotifications = () => {
    var data = {
      LeverName: 'Pulse page'
    }
    axiosInstance
      .post(`${LocalApiBaseUrl}UserGuide/GetUserHelpTopicCount`, data)
      .then((response) => {
        this.setState({
          newHelpFeaturesCount: response.data.NewHelpTopicCount,
          isHelpCountLoaded: true
        })
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  componentDidMount() {
    this.GetHelpFeatureNotifications();
    if (
      !this.props.QcOverallScore.QualityScoreDatas.QualityScoreTabDatas.length
    ) {
      this.getPulseQualityScore();
    }
  }

  componentDidUpdate(previousProps) {
    ReactTooltip.rebuild();
    const { pulseWholeData, pulsePageData, pulseReduxData } = this.props;

    if (
      previousProps.pulseReduxData.Offering !== pulseReduxData.Offering
      || previousProps.pulseReduxData.SubOffering !== pulseReduxData.SubOffering
    ) {
      let selectedPulseDataCategory = '';

      if (
        (previousProps.pulseWholeData !== pulseWholeData
          || previousProps.pulsePageData !== pulsePageData)
        && !_.isEmpty(pulsePageData)
      ) {
        selectedPulseDataCategory = _.get(pulsePageData, '0.TileName_En');
      }

      this.setState({
        selectedPulseDataCategory,
      });
    }

    if (
      previousProps.pulseWholeData !== pulseWholeData
      || previousProps.pulsePageData !== pulsePageData
      || previousProps.pulseReduxData.Offering !== pulseReduxData.Offering
      || previousProps.pulseReduxData.SubOffering !== pulseReduxData.SubOffering
    ) {
      if (
        previousProps.pulseReduxData.Offering !== pulseReduxData.Offering
        || previousProps.pulseReduxData.SubOffering !== pulseReduxData.SubOffering
      ) {
        this.getPulseQualityScore();
      }

      const storeData = [...pulsePageData];

      if (storeData.length) {
        const acunterTools = [];
        const clientTools = [];

        _.forEach(storeData, (each) => {
          if (each.TileName_En === 'Work Orchestration') {
            each.pulseDashBoardApplicaionModels.map((e) => {
              if (e.IsAccentureTool === true) {
                acunterTools.push(e);
              } else {
                clientTools.push(e);
              }
            });
          }
        });

        this.setState({
          clientTools,
          acunterTools,
          data: storeData,
        });
      } else {
        this.setState({
          landData: [],
        });
      }

      let assetData = [];
      const wholedata = [];
      _.forEach(pulseWholeData, (eachdataval) => {
        wholedata.push(eachdataval);
      });

      _.forEach(wholedata, (eachpulse) => {
        if (
          eachpulse.OfferingName === pulseReduxData.Offering
          && eachpulse.Process === pulseReduxData.SubOffering
        ) {
          assetData = eachpulse.AssetsData;
        }
      });
      this.setState({ assetData });
    }
  }

  getPulseQualityScore = () => {
    axiosInstance
      .get(`${LocalApiBaseUrl}PulseDashboard/GetPulsePageQualityScore`, {
        params: {
          OrgEntityID: this.props.selectedClientId,
          OfferingID: this.props.pulseReduxData.offeringId,
          LanguageID: store.getState().CurrentFilter.languageData.id,
        },
      })
      .then((response) => {
        if (response.data) {
          store.dispatch({
            type: 'SAVE_QC_SCORE_OF_EACH_TAB',
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  };

  QCScorePercentModalClose = () => {
    this.setState({ QCScorePercentStatus: false });
  };

  summaryModalShow = (selectedAsset, URL, OpriaURL, Period) => {
    this.setState({
      summaryStatus: true,
      selectedsummaryViewAsset: selectedAsset,
      selectedsummaryViewAssetURL: URL,
      OpriaURL,
      Period
    });
  };

  summaryModalClose = () => {
    this.setState({ summaryStatus: false });
  };

  AddAsserts = (val, tabname, tabname_en, TileID) => {
    const details = { ...this.state.addassertDetails };
    details.tabName = tabname;
    details.tabName_En = tabname_en;
    details.Offering = this.props.pulseReduxData.Offering;
    details.SubOffering = this.props.pulseReduxData.SubOffering;
    this.setState({
      showAddAserts: val,
      addassertDetails: details,
      TileID,
    });
  };

  closeAddNewAsset = (mode) => {
    this.setState({
      showAddNewAsset: false,
      showAddAserts: mode !== 'edit',
    });
  };

  showAddNewAsset = (e, data, tabname, tabname_en) => {
    data = _.isNil(data) ? {} : data;
    if (!_.isNil(data) && tabname !== undefined) {
      const details = { ...this.state.addassertDetails };
      details.tabName = tabname;
      details.tabName_En = tabname_en;
      details.Offering = this.props.pulseReduxData.Offering;
      details.SubOffering = this.props.pulseReduxData.SubOffering;
      this.setState({ addassertDetails: details });
    }
    this.setState({
      showAddNewAsset: true,
      showAddAserts: false,
      editToolData: data,
    });
  };

  hideAsserts = (val) => {
    this.setState({ showAddAserts: val });
  };

  autosaveDelete = (orgEntityID, offeringId, data) => {
    axiosInstance
      .delete(
        `${LocalApiBaseUrl}PulseDashBoard/AutoSaveDeleteToolMapping`,
        {
          params: {
            ApplicationMappingID: data.ApplicationMappingID,
            OfferingID: offeringId,
            OrgEntityID: orgEntityID,
          },
        },
      )
      .then((response) => {
        if (response.data.IsSuccess) {
          this.props.onDeleteAsset(data);
          this.setState({
            isAPILoading: false,
          });
          this.getPulseQualityScore();
        } else {
          window.alert(response.data.StatusMessage);
        }
      })
      .catch((error) => {
        this.setState({ isAPILoading: false });
        trycatchAlertPopup(error);
      });
  }

  ismindmapexist = (orgEntityID, offeringId, data) => {
    let Literal = i18n.t("Mind_Map_Pulse_Tool_Delete");
    let isExist = false;
    axiosInstance
      //.get(`${LocalApiBaseUrl}PulseDashBoard/isMappingExist`, {
      .get(`${LocalApiBaseUrl}Analytics/isMappingExistinMindmap`, {
        params: {
          OrgEntityID: orgEntityID,
          OfferingID: offeringId,
          PulseToolConfigurationID: 0,
          ApplicationMappingID: data.ApplicationMappingID,
          SOCategoryID: data.SOCategoryID
        },
      })
      .then((response) => {
        if (response.data) {
          if (window.confirm(Literal)) {
            this.autosaveDelete(orgEntityID, offeringId, data);
          }
        }
        else {
          this.autosaveDelete(orgEntityID, offeringId, data);
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
    return isExist;
  }
  onDelete = (data) => {
    let Literal = i18n.t("Pulse_Tool_Delete");
    let confirmLiteral = i18n.t("confirm_literal");
    let levermessage = data.SOCategoryID === 3 ? i18n.t("Mapping_WO") :
      data.SOCategoryID === 4 ? i18n.t("Mapping_II") : "";

    let { pulseReduxData, clientData } = this.props;
    let offeringId = pulseReduxData.offeringId;
    let orgEntityID = clientData.id;
    axiosInstance
      //.get(`${LocalApiBaseUrl}PulseDashBoard/isMappingExist`, {
      .get(`${LocalApiBaseUrl}PulseDashBoard/isMappingExist`, {
        params: {
          OrgEntityID: orgEntityID,
          OfferingID: offeringId,
          PulseToolConfigurationID: 0,
          ApplicationMappingID: data.ApplicationMappingID,
          SOCategoryID: data.SOCategoryID
        },
      })
      .then(async (response) => {
        if (response.data) {
          if (window.confirm(`${Literal} ${levermessage} ${confirmLiteral}`)) {
            this.ismindmapexist(orgEntityID, offeringId, data);
          }
        }
        else {
          this.ismindmapexist(orgEntityID, offeringId, data);
        }
      }
      )
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  };


  handleExitConfigure = () => {
    const { data } = this.state;
    let emptyCategories = data.map(category => {
      if (_.isEmpty(category.pulseDashBoardApplicaionModels)) {
        return category.TileName
      }
    })
    emptyCategories = emptyCategories.filter(tilename => tilename !== undefined)
    emptyCategories.length > 0 &&
      window.alert(`Tools for ${emptyCategories.join(', ')} category are not listed`)
    this.props.handleExit();
  };

  handleLoaderfromConfirmPopup = (value) => {
    this.setState({
      isAPILoading: value,
    });
  };

  showQcGraph = (val1, val2) => {
    this.setState({ showQcGraph: val1, selectedTab: val2 });
  };

  handleClickPulseDataCategory = (categoryName) => {
    this.setState({
      selectedPulseDataCategory: categoryName,
    });
  };

  disabledAssetsLink = () => {
    this.setState({ enableAssetsLinkStatus: false });
  };

  getUrl = (reqdata) => {
    axiosInstance
      .post(`${LocalApiBaseUrl}Common/GetURL?key=${reqdata}`)
      .then((response) => {
        window.open(response.data, '_blank');
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  };

  render() {
    const {
      pulseReduxData,
      pulsePageData,
      filterDetails,
      QcOverallScore,
      clientData,
      maxSequences,
      SelectedFilter,
      configureStatus,
      MorItemsData,
      pulseWholeData,
      pulseFilterData,
      leverDescription
    } = this.props;

    const {
      isAPILoading,
      showQcGraph,
      selectedTab,

      showAddAserts,

      data,
      TileID,
      assetData,
      addassertDetails,
      showAddNewAsset,
      editToolData,
      summaryStatus,
      selectedsummaryViewAsset,
      selectedsummaryViewAssetURL,
      QCScorePercentStatus,
      selectedPulseDataCategory,
      enableAssetsLinkStatus,
      linkURL,
      isIFrame,
    } = this.state;

    const soCategoryName = 'Pulse Page';

    let tabQcScore = 0;
    let tabUnit = '';
    let hasQcSocreData = false;

    if (soCategoryName) {
      if (
        QcOverallScore
        && QcOverallScore.QualityScoreDatas
        && QcOverallScore.QualityScoreDatas.QualityScoreTabDatas.length
      ) {
        QcOverallScore.QualityScoreDatas.QualityScoreTabDatas.filter((each) => {
          if (each.LeverName_En === soCategoryName) {
            tabQcScore = each.AchievedScore;
            tabUnit = each.Unit;
            hasQcSocreData = true;
          }
        });
      }
    }

    const clientName = _.get(clientData, 'name', '');
    let clientLogo = _.get(clientData, 'imageName', '');
    if (!_.isEmpty(clientLogo)) {
      clientLogo = LocalApiBaseUrlImage + clientLogo;
    } else {
      clientLogo = noClientLogo;
    }

    const { pulseData } = pulseReduxData;

    const pulseDataCategories = _.map(pulseData, (eachPulseData) => ({
      TileName: eachPulseData.TileName,
      TileName_En: eachPulseData.TileName_En,
      TileName_En_lowerCase: _.toLower(eachPulseData.TileName_En),
    }));

    let selectedPulseDataCategoryDetails = {};
    let toolsArr = [];
    let accentureTools = [];
    let clientTools = [];

    // FILTER THE DATA BASED ON THE SELECTED CATEGORY
    if (!_.isEmpty(selectedPulseDataCategory)) {
      selectedPulseDataCategoryDetails = _.find(pulseData, {
        TileName_En: selectedPulseDataCategory,
      });
      toolsArr = _.sortBy(
        _.get(
          selectedPulseDataCategoryDetails,
          'pulseDashBoardApplicaionModels',
          [],
        ),
        ['SequenceNumber'],
      );
    }
    const toolsArrChunked = _.chunk(toolsArr, 2);

    if (!_.isEmpty(toolsArr)) {
      accentureTools = _.filter(toolsArr, { ApplicationCategory: 'Accenture' });
      clientTools = _.filter(toolsArr, { ApplicationCategory: 'Client' });
    }

    let msgNoToolsForSelectedPulseDataCategory = '';
    if (_.toLower(selectedPulseDataCategory) === 'human + machine talent') {
      msgNoToolsForSelectedPulseDataCategory = i18n.t('Add_Resource_HM');
    } else if (_.toLower(selectedPulseDataCategory) === 'work orchestration') {
      msgNoToolsForSelectedPulseDataCategory = i18n.t('Add_Workflow');
    } else if (
      _.toLower(selectedPulseDataCategory) === 'insights & intelligence'
    ) {
      msgNoToolsForSelectedPulseDataCategory = i18n.t('Add_Business_Insights');
    } else if (_.toLower(selectedPulseDataCategory) === 'diverse data') {
      msgNoToolsForSelectedPulseDataCategory = i18n.t('Add_Benchmarking_tools');
    }
    let subOfferings = [];
    if (pulseFilterData && pulseFilterData.offerings && SelectedFilter) {
      pulseFilterData.offerings.forEach((each) => {
        if (parseInt(each.OfferingID, 10) === parseInt(SelectedFilter.OfferingId)) {
          subOfferings = each.ChildOfferings;
        }
      });
    }
    return (
      <>
        <LoadingOverlay
          className="custom-loader"
          fadeSpeed={0}
          spinner={<SynopsLoader />}
          active={isAPILoading}
        />

        <div className="pulse-dashboard push-pulse-filter">
          {SelectedFilter && SelectedFilter.OfferingId && SelectedFilter.ProcessId ? createBreadCrumbs(subOfferings, SelectedFilter.OfferingId, SelectedFilter.ProcessId, pulseFilterData.offerings) : ''}
          {pulseData.length > 0
            && SelectedFilter.OfferingId
            && SelectedFilter.ProcessId ? (
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-9">
                  <div className="pulse-header-controls">
                    <div className="pulse-pagename">
                      {i18n.t('SynOps Assets')}
                    </div>

                    <div className="pulse-btns">
                      <ul>
                        <NavLink
                          to={{
                            pathname: "/helpfeatures",
                            state: {
                              name: 'Pulse Page'
                            }
                          }}
                        >
                          <li className="help-notification">
                            {this.state.isHelpCountLoaded &&
                              <a data-tip data-for="help-notification-tooltip" href="javascript:void(0)">
                                {this.state.newHelpFeaturesCount > 0 &&
                                  <span>
                                    {this.state.newHelpFeaturesCount > 9 ? i18n.t('9+') : this.state.newHelpFeaturesCount}
                                  </span>
                                }
                              </a>
                            }
                          </li>
                          {this.state.isHelpCountLoaded &&
                            <ReactTooltip
                              id="help-notification-tooltip"
                              effect="solid"
                              place="left"
                            >
                              <span>
                                {this.state.newHelpFeaturesCount > 0
                                  ? (this.state.newHelpFeaturesCount + " " + i18n.t('New') + " " +
                                    (this.state.newHelpFeaturesCount == 1 ? i18n.t('Feature') : i18n.t('Features')) + " " +
                                    i18n.t('updated.') + " " + i18n.t('Click here for details'))
                                  : i18n.t('Click here for updates')
                                }
                              </span>
                            </ReactTooltip>
                          }
                        </NavLink>
                        {hasQcSocreData && pulseReduxData.offeringId && (
                          <li className="pulse-qcscore">
                            <span className="qcscore-status">
                              <span
                                onClick={this.showQcGraph.bind(
                                  this,
                                  true,
                                  soCategoryName,
                                )}
                                className={
                                  tabQcScore < 50
                                    ? 'qcscore-percent red-status'
                                    : tabQcScore >= 50 && tabQcScore <= 75
                                      ? 'qcscore-percent orange-status'
                                      : 'qcscore-percent green-status'
                                }
                              >
                                QC Score
                                {' '}
                                {tabQcScore}
                                {tabUnit}
                              </span>

                              {showQcGraph && (
                                <TabQCScore
                                  showQcGraph={this.showQcGraph}
                                  selectedTab={selectedTab}
                                />
                              )}
                            </span>
                          </li>
                        )}
                        <li onClick={() => this.handleExitConfigure()}>
                          {i18n.t('Exit_button')}
                        </li>
                      </ul>
                    </div>
                  </div>

                  {/* START - PULSE DATA CATEGORIES SECTION */}
                  <div className="pulse-modules">
                    <ul>
                      {_.map(pulseDataCategories, (eachItem) => {
                        const { TileName_En } = eachItem;
                        const titleForDisplay = eachItem.TileName;
                        const { TileName_En_lowerCase } = eachItem;

                        const titleFirstPart = _.trim(
                          titleForDisplay.substring(
                            0,
                            titleForDisplay.lastIndexOf(' ') + 1,
                          ),
                        );
                        const titleLastPart = _.trim(
                          titleForDisplay.substring(
                            titleForDisplay.lastIndexOf(' ') + 1,
                            titleForDisplay.length,
                          ),
                        );

                        return (
                          <>
                            <li
                              key={TileName_En_lowerCase}
                              onClick={() => {
                                this.handleClickPulseDataCategory(TileName_En);
                                setTimeout(() => {
                                  this.handleClickPulseDataCategory(TileName_En);
                                }, 100);
                              }}
                              className={classNames({
                                active:
                                  _.toLower(selectedPulseDataCategory)
                                  === TileName_En_lowerCase,
                              })}
                            >
                              {titleFirstPart}
                              {!_.isEmpty(titleLastPart) && (
                                <>
                                  <br />
                                  {titleLastPart}
                                </>
                              )}

                              <span
                                className={classNames(
                                  {
                                    'human-machine':
                                      TileName_En_lowerCase
                                      === 'human + machine talent',
                                  },
                                  {
                                    'work-orch':
                                      TileName_En_lowerCase
                                      === 'work orchestration',
                                  },
                                  {
                                    'insights-inte':
                                      TileName_En_lowerCase
                                      === 'insights & intelligence',
                                  },
                                  {
                                    'diverse-data':
                                      TileName_En_lowerCase === 'diverse data',
                                  },
                                )}
                              />
                              <span className="lever-description" data-tip data-for={TileName_En_lowerCase}></span>
                            </li>
                            {
                              leverDescription.length > 0 &&
                              <div className="lever-description-wrapper">
                                <ReactTooltip place="right" effect="solid" id={TileName_En_lowerCase}>
                                  <span>{leverDescription.find((a) => a.tab === TileName_En).description}</span>
                                </ReactTooltip>
                              </div>
                            }

                          </>
                        );
                      })}
                    </ul>
                  </div>
                  {/* END - PULSE DATA CATEGORIES SECTION */}

                  {/* START - SECTION TO DISPLAY DETAILS OF CURRENTLY SELECTED PULSE CATEGORY */}
                  <div className="pulse-modules-wrapper">
                    <div className="selected-head">
                      <p
                        className={classNames(
                          {
                            'human-grey':
                              _.toLower(selectedPulseDataCategory)
                              === 'human + machine talent',
                          },
                          {
                            'worko-grey':
                              _.toLower(selectedPulseDataCategory)
                              === 'work orchestration',
                          },
                          {
                            'insightsi-grey':
                              _.toLower(selectedPulseDataCategory)
                              === 'insights & intelligence',
                          },
                          {
                            'diversed-grey':
                              _.toLower(selectedPulseDataCategory)
                              === 'diverse data',
                          },
                        )}
                      >
                        {selectedPulseDataCategoryDetails.TileName}
                      </p>

                      <div className="assets-info">
                        {!_.isEmpty(toolsArr) && (
                          <>
                            <p>
                              <img src={accentureLogo} alt="Accenture" />
                              {i18n.t('Pulse_Accenture_Assets')}
                              &nbsp;
                              <span>{_.size(accentureTools)}</span>
                            </p>
                            <p>
                              <img src={clientLogo} alt={clientName} />
                              {i18n.t('Pulse_Client_Assets')}
                              &nbsp;
                              <span>{_.size(clientTools)}</span>
                            </p>
                          </>
                        )}
                        <span
                          onClick={this.AddAsserts.bind(
                            this,
                            true,
                            selectedPulseDataCategoryDetails.TileName,
                            selectedPulseDataCategoryDetails.TileName_En,
                            selectedPulseDataCategoryDetails.TileID,
                          )}
                        >
                          +
                          {' '}
                          {i18n.t('Add_Asset')}
                        </span>
                      </div>
                    </div>

                    <div className="pulse-carousel-container">
                      {!_.isEmpty(toolsArr) ? (
                        <>
                          <OwlCarousel {...carouselOptions}>
                            {_.map(toolsArrChunked, (eachChunk, chunkIndex) => {
                              const tools = eachChunk;
                              return (
                                <div className="item" key={chunkIndex}>
                                  {_.map(tools, (eachTool) => {
                                    const { ApplicationMappingID } = eachTool;
                                    const { ApplicationName } = eachTool;
                                    const { ApplicationDescription } = eachTool;
                                    const { ApplicationCategory, ApplicationCategoryID } = eachTool;
                                    const { OrgEntityID } = eachTool;
                                    const { IsAAA } = eachTool;

                                    let url = !_.isEmpty(eachTool.URL)
                                      && eachTool.URL !== 'NA'
                                      ? eachTool.URL
                                      : '';
                                    if (!_.isEmpty(url)) {
                                      url = url.startsWith('www')
                                        ? `https://${url}`
                                        : url;
                                    }

                                    const icon = eachTool.Icon;
                                    return (
                                      <div
                                        className="item-details"
                                        key={ApplicationMappingID}
                                      >
                                        <div className="top-content">
                                          {icon && icon !== 'NA' ? (
                                            <img
                                              src={
                                                `data:image/png;base64,${icon}`
                                              }
                                              alt={ApplicationName}
                                              width="34px"
                                              height="34px"
                                            />
                                          ) : (
                                            <img
                                              src={
                                                defaultIcon
                                              }
                                              alt={"Default Image"}
                                              width="34px"
                                              height="34px"
                                            />
                                            
                                          )}
                                          {OrgEntityID !== null && 
                                                <span className="custom-tool top-right">
                                                  {"Custom"}
                                                </span>
                                                }
                                          <p className="item-name mb-0">
                                            {ApplicationName}
                                          </p>
                                          <p className="item-desc mb-0">
                                            {ApplicationDescription}
                                          </p>
                                        </div>

                                        <div className="bottom-content">
                                          <span className="d-flex">
                                            <p className="mb-0">
                                              {String(ApplicationCategoryID)
                                                === '1' && (
                                                  <img
                                                    src={accentureLogo}
                                                    alt={ApplicationCategory}
                                                  />
                                                )}
                                              {String(ApplicationCategoryID)
                                                === '2' && (
                                                  <img
                                                    src={clientLogo}
                                                    alt={clientName}
                                                  />
                                                )}
                                            </p>
                                            {eachTool.SummeryView && (
                                              <p
                                                className="mb-0 pulse-tool-summary"
                                                data-tip
                                                data-for="tool-summary-tooltip"
                                              >
                                                <img
                                                  src={ToolSummaryLogo}
                                                  alt="Tool-Summary"
                                                />
                                              </p>
                                            )}
                                            {IsAAA && (
                                                        <p className="mb-0 pulse-tool-summary">
                                                          <img
                                                          src={synopsAAAIcon}
                                                          alt="AAA"
                                                          />
                                                        </p>
                                                      )
                                                    }
                                          </span>

                                          {/* START - MENUS POPUP */}
                                          <div className="dropdown dropup">
                                            <span
                                              type="button"
                                              className="dropdown-toggle"
                                              data-toggle="dropdown"
                                            />
                                            <div className="dropdown-menu">
                                              {eachTool.SummeryView && (
                                                <a
                                                  href="javascript:void(0)"
                                                  className="dropdown-item"
                                                  onClick={() => {
                                                    this.summaryModalShow(
                                                      eachTool.ApplicationName,
                                                      eachTool.URL,
                                                      eachTool.OpriaURL,
                                                      eachTool.Period
                                                    );
                                                  }}
                                                >
                                                  <span className="tool-summary-icon" />
                                                  {i18n.t('Pulse_Tool_Summary')}
                                                </a>
                                              )}

                                              {!_.isEmpty(url) && (
                                                <a
                                                  className="dropdown-item"
                                                  href={url}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  <span className="link-icon" />
                                                  {i18n.t('Link')}
                                                </a>
                                              )}

                                              <a
                                                href="javascript:void(0)"
                                                className="dropdown-item"
                                                onClick={(e) => {
                                                  this.showAddNewAsset(
                                                    e,
                                                    eachTool,
                                                    selectedPulseDataCategoryDetails.TileName,
                                                    selectedPulseDataCategoryDetails.TileName_En,
                                                  );
                                                }}
                                              >
                                                <span className="edit-icon" />
                                                {i18n.t('Edit')}
                                              </a>

                                              <a
                                                href="javascript:void(0)"
                                                className="dropdown-item"
                                                onClick={this.onDelete.bind(
                                                  this,
                                                  eachTool,
                                                )}
                                              >
                                                <span className="remove-icon" />
                                                {i18n.t('Remove')}
                                              </a>
                                            </div>
                                          </div>
                                          {/* END - MENUS POPUP */}
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              );
                            })}
                          </OwlCarousel>
                          <ReactTooltip
                            place="bottom"
                            effect="solid"
                            id="tool-summary-tooltip"
                          >
                            <span>
                              {i18n.t(
                                'Tool summary is available for this Asset',
                              )}
                            </span>
                          </ReactTooltip>
                        </>
                      ) : (
                        !this.props.showLoaderOnPage && (
                          <div className="no-data-found noDataSec">
                            <img
                              src={noDataIcon}
                              alt={msgNoToolsForSelectedPulseDataCategory}
                            />
                            <p className="nodata-name">
                              {msgNoToolsForSelectedPulseDataCategory}
                            </p>
                            <p className="nodata-info">
                              {i18n.t('Pulse_Click_On_Add_Asset_To_Add_Tools')}
                            </p>
                            <div className="nodatabottom">
                              <div className="row">
                                <div className="col-lg-6 text-center">
                                  <h1>{i18n.t('Dos')}</h1>
                                  <ul>
                                    <li>
                                      1.
                                      {i18n.t('Provide_min_tool')}
                                    </li>
                                    <li>
                                      2.
                                      {' '}
                                      {i18n.t('Tools_can_client_acc')}
                                      {' '}
                                    </li>
                                  </ul>
                                </div>
                                <div className="col-lg-6 text-center">
                                  <h1>
                                    {i18n.t('Donts')}
                                    {' '}
                                  </h1>
                                  <ul>
                                    <li>
                                      1.
                                      {i18n.t('One_tool_can_not')}
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                  {/* END - SECTION TO DISPLAY DETAILS OF CURRENTLY SELECTED PULSE CATEGORY */}
                </div>

                {/* START - RIGHT SIDE SECTION */}
                <div className="col-lg-3">
                  {/* {pulseWholeData[0]
                    && procurementOfferingList.findIndex(f => f == String(
                      pulseWholeData[0].OfferingName_En
                    )) > -1 ? (
                    <div className="pulsehub-wrapper mt-3">
                      <div className="synops-hub procurement-section">
                        <div className="hub-btnsection">
                          <h4>{i18n.t('Synops_for_Procurement')}</h4>
                          <span onClick={() => this.getUrl('Radix')}>
                            <a rel="noopener noreferrer">
                              {i18n.t('Visit_Portal')}
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )} */}
                  <div className="pulsehub-wrapper mt-3">
                    {/* <div className="synops-hub">
                      <div className="hub-btnsection">
                        <h4>{i18n.t('SynOps_Hub')}</h4>
                        <span onClick={() => this.getUrl('SynOpsHub')}>
                          <a rel="noopener noreferrer">{i18n.t('Visit_Hub')}</a>
                        </span>
                      </div>
                      <div className="hub-imgsection">
                        <img
                          src={synopsIcon}
                          alt="SynOps HUB"
                          height="87px"
                          width="87px"
                        />
                      </div>
                    </div> */}
                    {/* Operation Reviews Section Start */}
                    <MORCaseStudy
                      MorItemsData={MorItemsData}
                      configureStatus={configureStatus}
                      clientData={this.props.clientData}
                      userRole={this.props.userRole}
                      history={this.props.historyval}
                      filterDetails={this.props.filterValues}
                      ispulse
                      {...this.props}
                    />
                    {/* CaseStudy Section End */}
                  </div>
                </div>
                {/* END - RIGHT SIDE SECTION */}
              </div>
            </div>
          ) : SelectedFilter.OfferingId && SelectedFilter.ProcessId ? (
            <div />
          ) : (
            <div className="select-filter-msg">
              <img
                src={noFilter}
                alt={i18n.t('Pulse_No_Offering_Suboffering_Selected')}
              />
              <p className="mb-0">
                {i18n.t('Pulse_No_Offering_Suboffering_Selected')}
              </p>
            </div>
          )}
        </div>

        {showAddAserts && (
          <AddAsserts
            pulsePageData={pulsePageData}
            data={data}
            TileID={TileID}
            show={showAddAserts}
            assetData={assetData}
            details={addassertDetails}
            offeringId={pulseReduxData.offeringId}
            showAddNewAsset={this.showAddNewAsset}
            hideAddAsserts={this.hideAsserts}
            maxSequences={maxSequences}
            getPulseQualityScore={this.getPulseQualityScore}
            handleLoader={this.handleLoaderfromConfirmPopup}
          />
        )}

        {showAddNewAsset && (
          <AddNewAsset
            show={showAddNewAsset}
            close={this.closeAddNewAsset}
            details={addassertDetails}
            offeringId={pulseReduxData.offeringId}
            editdata={editToolData}
            maxSequences={maxSequences}
          />
        )}

        {summaryStatus && (
          <AssetsSummaryView
            selectedsummaryViewAsset={selectedsummaryViewAsset}
            selectedsummaryViewAssetURL={selectedsummaryViewAssetURL}
            OpriaURL={OpriaURL}
            Period={Period}
            summaryStatus={summaryStatus}
            filterDetails={filterDetails}
            summaryModalClose={() => this.summaryModalClose()}
          />
        )}

        {enableAssetsLinkStatus && (
          <AssetsLinkURLModal
            enableAssetsLinkStatus={enableAssetsLinkStatus}
            disabledAssetsLink={this.disabledAssetsLink}
            linkURL={linkURL}
            isIFrame={isIFrame}
          />
        )}

        <Modal
          show={QCScorePercentStatus}
          onHide={() => this.QCScorePercentModalClose()}
          className="modal fade qcscorepercent-modal"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Pulse - Quality Score</Modal.Title>
          </Modal.Header>
          <Modal.Body />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  pulsePageData: state.CurrentFilter.pulsePageData.pulseData,
  pulseReduxData: state.CurrentFilter.pulsePageData,
  pulseWholeData: state.CurrentFilter.PulseData.data,
  QcOverallScore: state.CurrentFilter.QcOverallScore,
  clientData: state.CurrentFilter.ClientData,
  SelectedFilter: { ...state.CurrentFilter.SyncFilter },
});

const mapDispatchToProps = (dispatch) => ({
  onDeleteAsset: (data) => {
    dispatch({ type: 'DELETE_ASSET', payload: data });
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LandingPageAdmin);
